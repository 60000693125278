@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.app-container {
  background-color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.header-content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: flex-start;
  padding: 0 1rem;
}

.header-logo {
  height: 2rem;
}

.main-content {
  width: 100%;
  max-width: 1200px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rewards-title {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin: 2rem 0;
  color: #2d3748;
}

.rewards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.reward-card {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
}

.reward-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.reward-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.5rem;
  text-align: center;
}

.reward-description {
  color: #718096;
  margin-bottom: 1rem;
  flex-grow: 1;
  text-align: center;
}

.reward-validity {
  font-size: 0.9rem;
  color: #4a5568;
  margin-bottom: 1rem;
  text-align: center;
}

.reward-code {
  font-family: 'Courier New', monospace;
  font-size: 1rem;
  background-color: #f7fafc;
  padding: 0.75rem;
  border-radius: 0.5rem;
  text-align: center;
  margin-bottom: 1rem;
  word-break: break-all;
}

.copy-button {
  background-color: #4299e1;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
}

.copy-button:hover {
  background-color: #3182ce;
}

.copy-button.copied {
  background-color: #48bb78;
}

/* Error page styles */
.error-content {
  width: 100%;
  max-width: 600px;
  margin: 4rem auto;
  padding: 0 1rem;
  text-align: center;
}

.error-title {
  font-size: 2rem;
  color: #2d3748;
  margin-bottom: 2rem;
  text-align: center;
}

.error-message {
  background-color: #fff5f5;
  border-left: 4px solid #fc8181;
  padding: 1.5rem;
  border-radius: 0.5rem;
  text-align: center;
}

.error-message p {
  color: #c53030;
  font-size: 1rem;
  line-height: 1.5;
}

/* Loading spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .rewards-grid {
    grid-template-columns: 1fr;
    padding: 0.5rem;
    gap: 1rem;
  }

  .reward-card {
    padding: 1rem;
  }

  .rewards-title {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }

  .header {
    padding: 0.5rem;
  }

  .header-logo {
    height: 1.5rem;
  }

  .error-content {
    margin: 2rem auto;
  }

  .error-title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .main-content {
    padding: 1rem;
  }
}

/* No rewards message */
.no-rewards {
  text-align: center;
  color: #4a5568;
  font-size: 1.2rem;
  margin: 2rem 0;
}